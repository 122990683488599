import * as React from "react";
import { SelectInput, ChipField, ReferenceField, NumberField, BooleanInput, TabbedForm, FormTab, FunctionField, Tab, TabbedShowLayout, EditButton, TopToolbar, DateField, Show, SimpleShowLayout, RichTextField, DateInput, Edit, List, Datagrid, TextField, EmailField, SimpleForm, TextInput, ReferenceInput, Create } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices
} from 'react-admin';

const postFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
    // <TextInput label="Status" source="status" defaultValue="Complete" />,
];

export const PicTransList = props => (
    <List {...props} filters={postFilters} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="ID" sortByOrder="DESC"/>
                <TextField source="sessionID" label="Session"/>
            <TextField source="siteID" label="Site"/>
            <TextField source="pump" />
            <NumberField source="amount" options={{ style: 'currency', currency: 'USD' }} />
            <ChipField source="status" />
            <DateField source="transDate"  showTime options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}/>
            <EmailField source="username" />
        </Datagrid>
    </List>
);



export const PicTransEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextField disabled label="Id" source="id" />

                <TextField source="sessionID" label="Session id"/>
                <TextField source="siteID" label="Site"/>
                <TextField source="pump" label="Pump#"/>
                <NumberField source="amount" options={{ style: 'currency', currency: 'USD' }} />
                <TextField source="transDate" label="Transaction date"/>
                <EmailField source="username" />
                <TextField source="grade" />
                <TextField source="volume" />
                <TextField source="price" label="Fuel price"/>
                <NumberField source="changeAmount" options={{ style: 'currency', currency: 'USD' }} />
                <SelectInput source="status" choices={[
                    { id: 'AUTHORIZE', name: 'Authorize' },
                    { id: 'CANCEL', name: 'Cancel' },
                    { id: 'COMPLETE', name: 'Complete' },
                ]} />

        </SimpleForm>
    </Edit>
);
