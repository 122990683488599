import * as React from "react";
import { BooleanField, BooleanInput, TabbedForm, FormTab, FunctionField, Tab, TabbedShowLayout, EditButton, TopToolbar, DateField, Show, SimpleShowLayout, RichTextField, DateInput, Edit, List, Datagrid, TextField, EmailField, SimpleForm, TextInput, ReferenceInput, Create } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices
} from 'react-admin';

export const SiteList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="locationId" label="ID"/>
            <BooleanField source="online" />
            <TextField source="name" />
            <FunctionField label="Address" render={record => `${record.street1}, ${record.city}, ${record.state} - ${record.zip}`} />
            <FunctionField label="Lat/Long" render={record => `${record.latitude} / ${record.longitude}`} />
            <DateField source="contractEffectiveDate" label="Since"/>
            <FunctionField label="Contact" render={record => `${record.contact}, ${record.email} ,${record.phone}`} />
        </Datagrid>
    </List>
);



export const SiteCreate = props => (
    <Create title="Create a new Site" {...props}>
        <SimpleForm>

<TextInput source="name" label="Location Name"  validate={required()} />
<TextInput label="ARCO ID" source="locationId"  validate={required()} />
<TextInput label="Marathon Customer ID" source="marathonCustomerId"  validate={required()} />
<TextInput source="street1" label="Address line 1" validate={required()} />
<TextInput source="street2" label="Address line 2" />
<TextInput source="state" validate={required()} />
<TextInput source="city" validate={required()} />
<TextInput source="zip" validate={required()} />
<TextInput source="latitude"  validate={required()} />
<TextInput source="longitude"  validate={required()} />
<TextInput source="contact"  validate={required()} />
<TextInput source="email"  />
<TextInput source="phone"  validate={required()} />
<BooleanInput source="online" label="Site online" />
<BooleanInput source="carchargeAvail"  label="Electric Charging"/>
<BooleanInput source="carwashAvail"  label="Car Wash"/>
<BooleanInput source="foodAvail"  label="Food court"/>
<BooleanInput source="fuelAvail" label="Fuel" />
        </SimpleForm>
    </Create>
);


export const SiteEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />

            <TextInput source="name" label="Location Name" validate={required()} />
            <TextInput label="ARCO ID" source="locationId" validate={required()}  />
            <TextInput label="Marathon Customer ID" source="marathonCustomerId"  validate={required()} />
            <TextInput source="street1" label="Address line 1" validate={required()} />
            <TextInput source="street2" label="Address line 2" />
            <TextInput source="state" validate={required()} />
            <TextInput source="city" validate={required()} />
            <TextInput source="zip" validate={required()} />
            <TextInput source="latitude"  validate={required()} />
            <TextInput source="longitude"  validate={required()} />
            <TextInput source="contact"  />
            <TextInput source="email"  />
            <TextInput source="phone"  />
            <BooleanInput source="online" label="Site online" />
            <BooleanInput source="carchargeAvail"  label="Electric Charging"/>
            <BooleanInput source="carwashAvail"  label="Car Wash"/>
            <BooleanInput source="foodAvail"  label="Food court"/>
            <BooleanInput source="fuelAvail" label="Fuel" />

            <DateInput label="Contract start date" source="contractEffectiveDate" validate={required()} />
        </SimpleForm>
    </Edit>
);
