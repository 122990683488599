import * as React from "react";
import { Card, CardContent, CardHeader } from '@material-ui/core';
import ipic from './iPIC.png';

export default () => (
  <>
    <Card>
        <CardHeader title="Welcome to the iPIC Service Portal" />
        <CardContent>
<img src="iPIC.png" width="500"/>
        </CardContent>
    </Card>
    </>
);
