import * as React from 'react';
import { forwardRef } from 'react';
import { Layout, AppBar, UserMenu, MenuItemLink } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { useLogout } from 'react-admin';
import { useKeycloak } from '@react-keycloak/web'
import ExitIcon from '@material-ui/icons/PowerSettingsNew';

const ConfigurationMenu = forwardRef(({ onClick }, ref) => (
  <MenuItemLink
      ref={ref}
      to="/configuration"
      primaryText="Configuration"
      leftIcon={<SettingsIcon />}
      onClick={onClick} // close the menu on click
  />
));
const ConfigurationMenu1 = forwardRef(({ onClick }, ref) => {

      const { keycloak, initialized } = useKeycloak()
      const handleClick = () => keycloak.logout();
      console.log('coming here');
  return (
  <MenuItemLink
      ref={ref}
      to="#"
      primaryText="Logout"
      leftIcon={<ExitIcon />}
      onClick={handleClick} // close the menu on click
  />
)});
const useStyles = makeStyles({
    avatar: {
        height: 30,
        width: 30,
    },
});
const MyCustomIcon = () => {
    const classes = useStyles();
    return (
        <Avatar
            className={classes.avatar}
            src="avatar.png"
        />
    )
};
const MyUserMenu = props => (
    <UserMenu {...props} icon={<MyCustomIcon />}>
            <ConfigurationMenu1 />
    </UserMenu>
);

const MyAppBar = props => <AppBar {...props} userMenu={<MyUserMenu />} />;

const MyLayout = props => <Layout {...props} appBar={MyAppBar} />;

export default MyLayout;
