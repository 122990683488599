import * as React from "react";
import { NumberField, BooleanInput, TabbedForm, FormTab, FunctionField, Tab, TabbedShowLayout, EditButton, TopToolbar, DateField, Show, SimpleShowLayout, RichTextField, DateInput, Edit, List, Datagrid, TextField, EmailField, SimpleForm, TextInput, ReferenceInput, Create } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices
} from 'react-admin';

const postFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
];
export const UserList = props => (
    <List {...props} filters={postFilters}>
        <Datagrid rowClick="edit">
            <TextField source="name" label="NickName"/>
            <FunctionField label="FullName" render={record => `${record.firstName} ${record.middleName} ${record.lastName}`} />
            <EmailField source="email" />
            <TextField source="phone" />
            <TextField source="companies" />
        </Datagrid>
    </List>
);



export const UserCreate = props => (
    <Create title="Create a new User" {...props}>
        <SimpleForm>
        <TextInput source="name" label="NickName" validate={required()}/>
        <TextInput source="firstName" validate={required()}/>
        <TextInput source="middleName" />
        <TextInput source="lastName" validate={required()}/>
        <TextInput source="email" validate={required()}/>
        <TextInput source="phone" validate={required()}/>
        <TextInput source="companies" validate={required()}/>
        <TextInput source="address" />
        <TextInput source="city" />
        <TextInput source="state" />
        <TextInput source="zip" />
        </SimpleForm>
    </Create>
);


export const UserEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextField disabled label="Id" source="id" />
            <EmailField source="email" validate={required()}/>
            <TextInput source="name" label="NickName" validate={required()}/>
                    <TextInput source="firstName" validate={required()}/>
                    <TextInput source="middleName" />
                    <TextInput source="lastName" validate={required()}/>
                    <TextInput source="phone" validate={required()}/>
                    <TextInput source="companies" validate={required()}/>
                    <TextInput source="address" />
                    <TextInput source="city" />
                    <TextInput source="state" />
                    <TextInput source="zip" />
        </SimpleForm>
    </Edit>
);
