import * as React from 'react';
import { forwardRef } from 'react';
import { useLogout } from 'react-admin';
import { useKeycloak } from '@react-keycloak/web'
import MenuItem from '@material-ui/core/MenuItem';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';

const MyLogoutButton = forwardRef((props, ref) => {
    const { keycloak, initialized } = useKeycloak()
    const handleClick = () => keycloak.logout();
    console.log('coming here');
    return (
        <MenuItem
            onClick={handleClick}
            ref={ref}
        >
            <ExitIcon /> Logouts
        </MenuItem>
    );
});

export default MyLogoutButton;
