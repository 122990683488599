import * as React from "react";

import { fetchUtils, Admin, Resource, ListGuesser, EditGuesser } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import ReceiptIcon from '@material-ui/icons/Receipt';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import LocalGasStation from '@material-ui/icons/LocalGasStation';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { SiteList, SiteCreate, SiteEdit} from './sites';
import { UserList, UserCreate, UserEdit} from './users';
import { PicTransList, PicTransCreate, PicTransEdit} from './pictrans';
import { NotificationsList, NotificationsCreate, NotificationsEdit} from './notifications';
import { CashboxList, CashboxEdit} from './cashbox';
import UserIcon from '@material-ui/icons/Group';
import { useKeycloak } from '@react-keycloak/web';
import Dashboard from './Dashboard';
import MyLogoutButton from './MyLogoutButton';
import MyLayout from './MyLayout';
import myTheme from './myTheme';

import dataProvider from './dataProvider';

const knownResources = [
  <Resource name="users"  icon={UserIcon} list={UserList} create={UserCreate} edit={UserEdit}/>,
    <Resource name="sites"  icon={LocalGasStation} create={SiteCreate} edit={SiteEdit} list={SiteList}/>,
      <Resource name="pictrans"  icon={ReceiptIcon} list={PicTransList} edit={PicTransEdit} />,
        <Resource name="cashbox"  icon={MonetizationOnIcon} list={CashboxList} edit={CashboxEdit} />,
          <Resource name="notifications"  icon={NotificationsActiveIcon}  list={NotificationsList} create={NotificationsCreate} edit={NotificationsEdit} />,
];

export default () => {
  const {keycloak, initialized} = useKeycloak();
  const httpClient = (url, options = {}) => {
      if (!options.headers) {
          options.headers = new Headers({ Accept: 'application/json' });
      }
      const token = localStorage.getItem('react-token');
      options.headers.set('Authorization', `Bearer ${keycloak.token}`);
      options.headers.set('Origin', `http://localhost:3000`);
      return fetchUtils.fetchJson(url, options);
  };

  return (
  <Admin theme={myTheme} layout={MyLayout}  title="iPIC" dataProvider={dataProvider('https://api.gstinc.link/api',httpClient)} dashboard={Dashboard}>
  {knownResources}
  </Admin>)
}
