import * as React from "react";
import { ArrayField, SelectInput, ChipField, ReferenceField, NumberField, BooleanInput, TabbedForm, FormTab, FunctionField, Tab, TabbedShowLayout, EditButton, TopToolbar, DateField, Show, SimpleShowLayout, RichTextField, DateInput, Edit, List, Datagrid, TextField, EmailField, SimpleForm, TextInput, ReferenceInput, Create } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices
} from 'react-admin';

const postFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
    // <TextInput label="Status" source="status" defaultValue="Complete" />,
];

export const CashboxList = props => (
    <List {...props} filters={postFilters}>
        <Datagrid rowClick="edit">
            <TextField source="siteID" label="Site"/>
            <TextField source="pic" />
            <DateField source="effDt" showTime label="Last Updated"/>
        </Datagrid>
    </List>
);



export const CashboxEdit = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
                <TextField source="id" label="ID"/>
                <TextField source="siteID" label="Site"/>
                <TextField source="pic" label="PIC"/>
                <DateField source="effDt" showTime label="Last Updated"/>

                <ArrayField source="cb" label="Cashbox History" sortable={true}>
                    <Datagrid>
                        <TextField source="batch" label="Cash cleared" />
                        <NumberField source="tot" label="Amount" options={{ style: 'currency', currency: 'USD' }}/>
                    </Datagrid>
                </ArrayField>

        </SimpleShowLayout>
    </Show>
);
